import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/common/Layout";
import { paginateArray } from "../../utils/helpers";
import useWindowSize from "../../hooks/useWindowSize";
import { ProductsListingPageProps } from "../../types/products-listing";
import ProductsGrid from "../../components/products/listing/ProductsGrid";
import ProductsSlider from "../../components/products/listing/ProductsSlider";
import Pagination from "../../components/common/Pagination";
import FilterByCategoryBadgesTypes from "../../components/common/FilterByCategoryBadges";
import SlideBanner from "../../components/products/slideBanner/SlideBanner";
import Meta from "../../components/common/Meta";

const Products: React.FC<ProductsListingPageProps> = ({
  data,
  path,
}: ProductsListingPageProps) => {
  const allCategories = data?.allStrapiProductType?.nodes;
  const allProducts = data?.allStrapiProduct?.nodes;
  const pageInfo = data?.allStrapiProduct?.pageInfo;
  const screen = useWindowSize();
  const paginatedProducts = paginateArray(allProducts, 3);
  const isIndexPage = pageInfo.currentPage == 1;

  const meta = {
    title: `製品｜（株）Digital Stacks${
      isIndexPage ? "" : " - " + pageInfo.currentPage
    }`,
    description: isIndexPage
      ? "Digital Stacksは、日本のお客さまのデジタルトランスフォーメーション（DX）を加速させるために、世界トップレベルのテクノロジー製品を提供しています。"
      : "",
    pathname: path,
  };

  return (
    <Layout>
      <Meta meta={meta} />
      <SlideBanner />
      <div className="px-4 xl:px-[200px]">
        <FilterByCategoryBadgesTypes
          isTagIconHidden={true}
          baseSlug="/products"
          categories={allCategories}
        />
      </div>
      <div className="px-4 xl:px-[110px]">
        {screen.width !== undefined && screen.width > 767 ? (
          <ProductsGrid products={allProducts} />
        ) : (
          <ProductsSlider products={paginatedProducts} />
        )}
        <Pagination
          isMinimal={true}
          className="mb-20"
          pageInfo={pageInfo}
          pathname={path.replace(/[0-9/]/g, "")}
        />
      </div>
    </Layout>
  );
};

export default Products;

export const query = graphql`
  query FetchProducts($skip: Int!, $limit: Int!, $type_name: String) {
    allStrapiProduct(
      limit: $limit
      skip: $skip
      sort: { order: ASC, fields: slug }
      filter: {
        product_types: { elemMatch: { type_name: { eq: $type_name } } }
        is_hidden_product_list: { ne: true }
      }
    ) {
      nodes {
        slug
        name
        has_no_tp_page
        card_description
        link
        Sections {
          ... on STRAPI__COMPONENT_SECTIONS_HERO_SECTION {
            subtitle
          }
        }
        logo {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(
                height: 126
                quality: 100
                width: 126
                aspectRatio: 1
                transformOptions: { fit: COVER, cropFocus: CENTER }
                webpOptions: { quality: 100 }
                placeholder: TRACED_SVG
                layout: FIXED
              )
            }
          }
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
        totalCount
      }
    }
    allStrapiProductType {
      nodes {
        type_name
      }
    }
  }
`;
