import React, { useRef } from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { SliderProducts } from "../../../types/products-listing";
import { NewProductCard } from "../../home/NewProductCards";
import "swiper/css/navigation";
import SwiperNavigation from "../../common/SwiperNavigation";

const breakpoints = {
  560: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  0: {
    slidesPerView: 1,
    spaceBetween: 24,
  },
};

const ProductsSlider: React.FC<SliderProducts> = ({
  products,
}: SliderProducts) => {
  const prevBtn = useRef<HTMLButtonElement>(null);
  const nextBtn = useRef<HTMLButtonElement>(null);

  return (
    <div className="[&_.swiper]:py-1 [&_.swiper]:sm:px-1 [&_.swiper]:pl-6 pt-10 pb-12 relative">
      <Swiper
        breakpoints={breakpoints}
        modules={[Navigation]}
        speed={1000}
        navigation={{
          prevEl: prevBtn?.current && prevBtn.current,
          nextEl: nextBtn?.current && nextBtn.current,
          disabledClass: "hidden",
        }}
      >
        {products.map((productColumn, index) => (
          <SwiperSlide key={index}>
            {productColumn.map((product) => (
              <NewProductCard product={product} key={product.name} />
            ))}
          </SwiperSlide>
        ))}
      </Swiper>
      <SwiperNavigation prevBtn={prevBtn} nextBtn={nextBtn} />
    </div>
  );
};

export default ProductsSlider;
