import React from 'react'
import { GridProducts } from '../../../types/products-listing'
import { NewProductCard } from '../../home/NewProductCards'

const ProductsGrid: React.FC<GridProducts> = ({ products }: GridProducts) => {
    return (
        <div className='grid grid-cols-3 gap-y-10 gap-x-12 pt-10 pb-12 lg:pt-20 lg:pb-14'>
            {
                products?.map((product) => <NewProductCard product={product} key={product.name} />)
            }
        </div>
    )
}

export default ProductsGrid