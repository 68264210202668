export const composeNewsUrl = (newsDataProps: NewsDataProps): string => {
  return `/${newsDataProps.blog_categories[0]?.slug}/${newsDataProps.slug}/`;
};

export const paginateArray = (
  originalArray: Array<any>,
  limit: number
): Array<Array<any>> => {
  const paginatedArray: Array<any> = [];
  for (let i = 0; i < Math.ceil(originalArray.length / limit); i++) {
    paginatedArray.push(originalArray.slice(i * limit, i * limit + limit));
  }
  return paginatedArray;
};

export const buildStrapiAssetUrl = (path: string): string => {
  return path.startsWith('/') ? `https://strapi-admin.digitalstacks.net${path}` : path;
};

export const purifiyImageData = (image: ImageProps): PurifiedImageProps => {
  const gatsbyImageData =
    image?.localFile?.childrenImageSharp[0]?.gatsbyImageData;
  const url = image?.url && buildStrapiAssetUrl(image?.url);
  const alt = image?.alternativeText || "Decorative Image";
  return {
    alt,
    url,
    gatsbyImageData,
  };
};

export const extractInternalLink = (link: string): string => {
  return link.replace(/http[s]*:\/\/[www.]*?digitalstacks.net/, "");
};

export const formateDate = (date: string): string => {
  const daysName = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri"];
  try {
    const dateObj = new Date(date);
    return `${dateObj.getFullYear()}.${
      dateObj.getMonth() + 1
    }.${dateObj.getDate()} ${daysName[dateObj.getDay()]}`;
  } catch (e) {
    console.log(e);
    return "";
  }
};

type PurifiedImageProps = {
  alt: string;
  url?: string;
  gatsbyImageData?: any;
};

type ImageProps = {
  alternativeText?: string;
  url?: string;
  localFile?: {
    childrenImageSharp?: any;
  };
};

interface NewsDataProps {
  slug: string;
  blog_categories: {
    slug?: string;
    title?: string;
  };
}
