import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useRef } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Lazy,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import useWindowSize from "../../../hooks/useWindowSize";
import Button from "../../common/Button";

const SlideBanner = () => {
  const screen = useWindowSize();
  const prevBtn = useRef<HTMLButtonElement>(null);
  const nextBtn = useRef<HTMLButtonElement>(null);

  return (
    <div className="relative mb-8 [&_.bg-image]:absolute [&_.bg-image]:z-[-1] [&_.bg-image]:w-full [&_.bg-image]:h-full [&_.bg-image]:top-0 [&_.bg-image]:left-0 [&_.bg-container]:relative [&_.bg-container]:w-full [&_.bg-container]:h-[400px]">
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Lazy, Autoplay]}
        pagination={{ clickable: true }}
        slidesPerView={1}
        autoplay={{ delay: 3500 }}
        speed={1000}
        navigation={{
          prevEl: prevBtn?.current && prevBtn.current,
          nextEl: nextBtn?.current && nextBtn.current,
        }}
        loop={true}
      >
        <SwiperSlide>
          <div className="bg-container">
            <StaticImage
              quality={100}
              src="../../../../static/images/products/bannerImage1.jpg"
              layout="fullWidth"
              objectFit="cover"
              alt="Background Image 1"
              className="bg-image"
            />
            <div className="bg-black absolute top-0 left-0 h-full w-full opacity-30"></div>
            <div className="absolute text-white w-full h-full flex flex-col justify-center items-center px-4 text-center">
              <h1 className="font-bold mb-3 text-[24px] lg:text-[32px] leading-[55px]">
                先端デジタルテクノロジー
              </h1>
              <p className="text-[22px] leading-[1.4]">
                ビジネスのデジタル化に寄与するクラウド製品の導入を支援
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="bg-container">
            {screen.width && screen.width > 630 ? (
              <div>
                <StaticImage
                  quality={100}
                  src="../../../../static/images/products/bannerImage2.png"
                  layout="fullWidth"
                  objectFit="cover"
                  alt="Background Image 2"
                  className="bg-image"
                />
                <div className="absolute text-white w-[55%] h-full flex flex-col justify-center pl-4 xl:pl-10">
                  <h1 className="font-bold mb-3 text-[24px] lg:text-[32px] leading-[55px] text-dsc-title">
                    企業業績向上のためのダイナミックなワーキングプラットフォーム
                    「Smartsheet」日本語サイト公開中
                  </h1>
                  <div>
                    <Button
                      title="サイトにアクセス"
                      path="https://smartsheet.digitalstacks.net/"
                      isExternal={true}
                      size="normal"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <a
                href="https://smartsheet.digitalstacks.net/"
                className="block relative w-full h-full"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  quality={100}
                  src="../../../../static/images/products/banner2Mobile.png"
                  layout="fullWidth"
                  objectFit="cover"
                  alt="Background Image 2"
                  className="bg-image"
                />
              </a>
            )}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="bg-container">
            {screen.width && screen.width > 630 ? (
              <div>
                <StaticImage
                  quality={100}
                  src="../../../../static/images/products/bannerImage3.jpg"
                  layout="fullWidth"
                  objectFit="cover"
                  alt="Background Image 3"
                  className="bg-image"
                />
                <div className="absolute text-white w-full h-full flex flex-col justify-center items-center px-4 text-center">
                  <h1 className="font-bold mb-3 text-[24px] lg:text-[32px] leading-[55px] max-w-[663px]">
                    マーケティングテクノロジー
                    <br />
                    コンサルティングサービス
                    <br />
                    Implement Digital
                  </h1>
                  <Button
                    title="サイトにアクセス"
                    path="https://implementdigital.com/"
                    isExternal={true}
                    size="normal"
                  />
                </div>
              </div>
            ) : (
              <a
                href="https://implementdigital.com/"
                className="block relative w-full h-full"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  quality={100}
                  src="../../../../static/images/products/banner3Mobile.png"
                  layout="fullWidth"
                  objectFit="cover"
                  alt="Background Image 2"
                  className="bg-image"
                />
              </a>
            )}
          </div>
        </SwiperSlide>
        {screen.width && screen.width < 630 && (
          <SwiperSlide>
            <div className="bg-container">
              <Link
                to="/release/japan-it-week-2022-digital-stacks-exhibit-announcement/"
                className="block relative w-full h-full"
              >
                <StaticImage
                  quality={100}
                  src="../../../../static/images/products/banner4Mobile.png"
                  layout="fullWidth"
                  objectFit="cover"
                  alt="Background Image 2"
                  className="bg-image"
                />
              </Link>
            </div>
          </SwiperSlide>
        )}
        <SwiperSlide>
          <div className="bg-container">
            {screen.width && screen.width > 630 ? (
              <div>
                <StaticImage
                  quality={100}
                  src="../../../../static/images/products/bannerImage4.png"
                  layout="fullWidth"
                  objectFit="cover"
                  alt="Background Image 4"
                  className="bg-image"
                />
                <div className="absolute text-white w-[55%] h-full flex flex-col justify-center pl-4 xl:pl-10">
                  <h1 className="font-bold mb-3 text-[24px] lg:text-[32px] leading-[55px] text-dsc-title">
                    世界基準のデータプライバシーコンプライアンスツール <br />
                    Secure Privacy
                  </h1>
                  <div>
                    <Button
                      title="サイトにアクセス"
                      path="https://www.digitalstacks.net/tp/secure-privacy/"
                      isExternal={true}
                      size="normal"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <a
                href="https://www.digitalstacks.net/tp/secure-privacy/"
                className="block relative w-full h-full"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  quality={100}
                  src="../../../../static/images/products/banner5Mobile.png"
                  layout="fullWidth"
                  objectFit="cover"
                  alt="Background Image 2"
                  className="bg-image"
                />
              </a>
            )}
          </div>
        </SwiperSlide>
      </Swiper>
      <button ref={prevBtn} className="top-[45%] left-2 absolute z-[2]">
        <BiChevronLeft size={42} color="#eeeeeee6" />
      </button>
      <button ref={nextBtn} className="top-[45%] right-2 absolute z-[2]">
        <BiChevronRight size={42} color="#eeeeeee6" />
      </button>
    </div>
  );
};

export default SlideBanner;
